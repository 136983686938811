import React from 'react';
import { Icon } from '@/core/Icon.atom';

interface RemovableTextButtonProps {
  label?: string;
  showFormat?: boolean;
  onClick?: any;
}

/**
 * Displays a close 'x' icon on text buttons that indicates that the text button can be removed. An example is in the
 * home screen search results.
 */
export const RemovableTextButton: React.FunctionComponent<RemovableTextButtonProps> = ({
  label,
  showFormat,
  onClick,
}) => {
  return (
    <div>
      {' '}
      {label}
      {showFormat && (
        <Icon icon="fa-close" type="text" onClick={onClick} testId="textButtonRemove" extraClassNames="ml10" />
      )}
    </div>
  );
};
